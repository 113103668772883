@mixin _768() {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin _1060() {
  @media (max-width: 1060px) {
    @content;
  }
}

.container-custom {
  width: 75%;
  @include _768() {
    width: 85%;
  }
}

.w-22 {
  width: 22%;
}

.w-100-px {
  width: 100px;
}

.w-max-content {
  width: max-content;
}

.left-65px {
  left: 65px;
}

.z-9 {
  z-index: 9;
}

.station {
  &:hover {
    path {
      fill: #fff;
    }
  }
}

.add-station {
  &:hover {
    path {
      fill: rgba(99, 102, 241, 1);
    }
  }
}

.bg-main-blue {
  background-color: #3065ed;
}

.color-main-blue {
  color: #3065ed;
}

.border-main-blue {
  border-color: #3065ed;
}

.customer-side-drawer {
  top: 0;
  right: -100%;
  z-index: 40;
  background-color: rgba(0, 0, 0, 0.25);

  // transition: 3s;
  &.backdrop-show {
    right: 0;
  }
}

.drawer-item {
  transition: 0.3s;
  top: 0;
  bottom: 0;
  z-index: 41;

  &.show-drawer {
    right: 0;
  }

  &.hide-drawer {
    right: -120%;
  }
}

.small-item {
  content: "div";
  display: inline-block;
  width: 4px;
  height: 30px;
  margin-right: 4px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 50%;
  left: -10px;
  z-index: 50;
}

.z-minus-1 {
  z-index: -1;
}

.transform_x svg {
  transition: transform 0.4s ease;
}

.transform_x:hover svg {
  transform: translateX(5px);
}

.slick-slide > div {
  margin: 0 10px;
  height: 100%;
}

.slick-list {
  margin: 0 -10px;
}

.slick-slide *:focus {
  outline: none;
}

.landing-footer-bg {
  background-color: #292c34;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  background: #fff;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.slick-dots {
  li {
    padding: 0 !important;
    display: inline-block !important;
    width: max-content !important;
    height: 100% !important;
    border-radius: 0.374rem;
  }
}

.slick-active {
  .slick-pagg {
    background-color: #3065ed !important;
    color: white !important;
  }
}

.faq-page-container ul {
  list-style: disc;
  padding-left: 14px;
}
.faq-page-container ul li {
  margin-bottom: 7px;
}

.faq-page-container ol {
  list-style: decimal;
  padding-left: 14px;
}
.faq-page-container li {
  margin-bottom: 7px;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

#news-card body {
  color: #fff;
  background: #1d1e1f;
}
