:focus,
button:focus,
.btn:focus,
.btn-sm:focus,
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
  outline: 2px solid rgba(0, 112, 244, 0.5);
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {

  &:focus {
    box-shadow: none !important;
  }
}
